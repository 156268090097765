import React from "react";
import {
  Box,
  Grid,
  Image,
  AspectRatio,
  Heading,
  Text,
  Center,
  Stack,
  Divider,
} from "@chakra-ui/react";
import TextSection from "../components/TextSection";
import Navbar from "../components/Navbar";
import Buyersclub from "../components/Buyersclub.js";
import Buyersclub2 from "../components/Buyersclub2.js";

function Order() {
  return (
    <Box>
      <Navbar />
      <Box m={{ base: 5, md: 10 }} px={{ base: 4, md: 10 }}>
        {/* For Businesses Section */}
        <Stack
          spacing={4}
          width={"100%"}
          direction={"column"}
          color={"white"}
          bgColor={"#4F8C97"}
          borderRadius={"md"}
          p={{ base: 4, md: 5 }} // Add padding for mobile
        >
          <Stack
            p={5}
            alignItems={"center"}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
            spacing={{ base: 4, md: 0 }} // Add spacing between items for mobile
          >
            <Stack
              width={{
                base: "100%",
                md: "40%",
              }}
              textAlign={{ base: "center", md: "left" }} // Center text on mobile
            >
              <Heading size={"lg"}>For Businesses</Heading>
            </Stack>
            <Stack
              width={{
                base: "100%",
                md: "60%",
              }}
              textAlign={{ base: "center", md: "left" }} // Center text on mobile
            >
              <Text>
                We are proud to collaborate with businesses and non-profit
                organizations, including co-ops, grocery stores, food markets,
                and restaurants. If you represent a business or a non-profit
                entity and would like to place an order with us, you can do so
                by emailing us at{" "}
                <b>
                  <a href="mailto:orders@sincerodistribution.com">
                    orders@sincerodistribution.com
                  </a>
                </b>
                .
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <Divider my={{ base: 5, md: 10 }} />

        {/* Buyersclub Sections */}
        <Buyersclub />
        <Buyersclub2 />

        {/* Thank You Section */}
        <Center>
          <Box
            m={{ base: 5, md: 10 }}
            p={{ base: 5, md: 10 }}
            align={"center"}
            textAlign={"center"} // Center align text on all screen sizes
          >
            <Heading
              size="lg"
              my={2}
              color={"#508B96"}
              fontSize={{ base: "md", md: "lg" }} // Adjust font size for mobile
            >
              Thank you for being a part of the “Cheese for Ukraine” project!
            </Heading>
            <Box maxW="2xl">
              <Text
                size="lg"
                my={2}
                fontStyle={"italic"}
                fontSize={{ base: "sm", md: "md" }} // Adjust font size for mobile
              >
                If you have any questions or need further assistance, please
                contact us at{" "}
                <a href="mailto:orders@sincerodistribution.com">
                  orders@sincerodistribution.com
                </a>
              </Text>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

export default Order;
