import React from "react";
import logo from "../../assets/sincero-rectangletransparent.png";
import "./NavbarHome.css";
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  useColorModeValue,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div className="navbar-container">
      <Box>
        <Flex minH={"80px"} px={{ base: 4 }} align={"center"}>
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={8} h={8} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "end", md: "start" }}>
            <Image
              src={logo}
              alt="Logo"
              htmlWidth={("100", "270")}
              m={{ base: "0", md: "20px" }}
            />

            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </div>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("white", "black");
  const linkHoverColor = useColorModeValue("gray.400");
  const popoverContentBgColor = useColorModeValue("gray.200", "gray.200");

  return (
    <Stack direction={"row"} spacing={4} align={"center"} minW={"lg"}>
      <Popover trigger={"hover"} placement={"bottom-start"}>
        <PopoverTrigger>
          <Text
            p={2}
            color={linkColor}
            _hover={{
              color: linkHoverColor,
            }}
            fontWeight={"bold"}
          >
            About <Icon as={ChevronDownIcon} />
          </Text>
        </PopoverTrigger>

        <PopoverContent
          border={0}
          boxShadow={"xl"}
          bg={popoverContentBgColor}
          p={4}
          rounded={"xl"}
          minW={"sm"}
        >
          <Stack>
            <Link href={"/mission-and-values"} color={"black"}>
              Mission and Values
            </Link>
            <Link href={"/cheese-for-ukraine"} color={"black"}>
              Cheese for Ukraine Project
            </Link>
          </Stack>
        </PopoverContent>
      </Popover>

      <Popover trigger={"hover"} placement={"bottom-start"}>
        <PopoverTrigger>
          <Text
            p={2}
            color={linkColor}
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            fontWeight={"bold"}
          >
            Products <Icon as={ChevronDownIcon} />
          </Text>
        </PopoverTrigger>

        <PopoverContent
          border={0}
          boxShadow={"xl"}
          bg={popoverContentBgColor}
          p={4}
          rounded={"xl"}
          minW={"sm"}
        >
          <Stack>
            <Link href={"/cow-cheese"} color={"black"}>
              Cow Cheese
            </Link>
            <Link href={"/goat-cheese"} color={"black"}>
              Goat Cheese
            </Link>
          </Stack>
        </PopoverContent>
      </Popover>

      <Button
        as={Link}
        href={"/supporters"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
        color={linkColor}
      >
        Supporters
      </Button>
      <Button
        as={Link}
        href={"/order"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
        color={linkColor}
      >
        Order
      </Button>
      <Button
        as={Link}
        href={"/contact"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
        color={linkColor}
      >
        Contact
      </Button>
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("black", "gray.800")}
      p={4}
      display={{ md: "none" }}
      spacing={4} // Add spacing for better alignment
    >
      <MobileNavItem label="About">
        <AboutSubNav /> {/* Use the same sub-navigation for consistency */}
      </MobileNavItem>
      <MobileNavItem label="Products">
        <ProductsSubNav /> {/* Add Products sub-navigation */}
      </MobileNavItem>
      <MobileNavItem label="Supporters" href={"/supporters"} />
      <MobileNavItem label="Order" href={"/order"} />
      <MobileNavItem label="Contact" href={"/contact"} />
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
          color: useColorModeValue("gray.400", "gray.200"),
        }}
      >
        <Text
          fontWeight={600}
          fontSize={"md"}
          color={useColorModeValue("white", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={5}
            h={5}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
          spacing={3} // Add spacing between child links
        >
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const AboutSubNav = () => {
  return (
    <Stack spacing={2}>
      <Link href={"/mission-and-values"} color={"white"} fontSize={"sm"}>
        Mission and Values
      </Link>
      <Link href={"/cheese-for-ukraine"} color={"white"} fontSize={"sm"}>
        Cheese for Ukraine Project
      </Link>
    </Stack>
  );
};

const ProductsSubNav = () => {
  return (
    <Stack spacing={2}>
      <Link href={"/cow-cheese"} color={"white"} fontSize={"sm"}>
        Cow Cheese
      </Link>
      <Link href={"/goat-cheese"} color={"white"} fontSize={"sm"}>
        Goat Cheese
      </Link>
    </Stack>
  );
};
